<template>
  <div>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Update A Product Group`"
            :visible.sync="isEditProductGroupDialogVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="productGroupForm"
                :rules="rules"
                label-position="top"
                ref="productGroupForm"
                class="demo-productGroupForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="Product Group Name"
                      prop="product_group_name"
                    >
                      <el-input v-model="productGroupForm.product_group_name"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="User Photo">
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handleUserPhotoPreview"
                        :on-remove="handleUserPhotoRemove"
                        :file-list="userPhotoFileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="editProductGroup('productGroupForm')"
                  > Save Product Group Changes</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="productGroupForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",
      selectedUserPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      // userPhotoFileLists: [],
      userPhotoFileLists: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
        {
          name: "food2.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      
      isEditProductGroupDialogVisible: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      productGroupForm: {
        product_group_id: "",
        product_group_name: "",
      },

      rules: {
        product_group_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    showProductGroupEditDialog: {
      required: true,
      type: Boolean,
    },
    
    productGroupData: {
      required: true,
      type: Object,
    },
  },

  watch: {
    showProductGroupEditDialog() {
      if (this.showProductGroupEditDialog === true) {
        this.isEditProductGroupDialogVisible = true;
        this.productGroupForm.product_group_id = this.productGroupData.product_group_id;
        this.productGroupForm.product_group_name =
          this.productGroupData.product_group_name;
        this.selectedUserPhotoFile = this.productGroupData.product_group_image;
        this.userPhotoFileLists = [
          {
            name: "cover_image",
            url: "this.productGroupData.product_group_image",
          },
        ];
      } else {
        this.isEditProductGroupDialogVisible = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditProductGroupDialog");
    },

    handleUserPhotoPreview(file) {
      console.log(file.raw);
      this.userPhotoFileLists = [file];
      this.selectedUserPhotoFile = file.raw;
    },

    handleUserPhotoRemove() {
      this.selectedUserPhotoFile = null;
    },

    async editProductGroup(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append(
              "product_group_id",
              this.productGroupForm.product_group_id
            );
            formData.append(
              "product_group_name",
              this.productGroupForm.product_group_name
            );
            formData.append("product_group_image", this.selectedUserPhotoFile);
            let response = await this.$http.put(`product-groups`, formData, {
              onUploadProgress: (progressEvent) => {
                this.imageUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
            if (
              response.data.success &&
              response.data.message == "PRODUCT_GROUP_UPDATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$emit("closeEditProductGroupDialog");
              this.$notify({
                title: "Success",
                message: "Product group updated successfully",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to update user",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>